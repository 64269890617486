
:root {
    --employees-gap: 16px;
    --employees-margin: 40px 0px 0px;
    --employee-padding: 16px;
    --img-width: 104px;
}

.employees {
    display: flex;
    gap: var(--employees-gap);
    margin: var(--employees-margin);
    flex-wrap: wrap;
}

.employees .employee {
    background-color: var(--white);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    padding: var(--employee-padding);
    min-width: 220px;
    flex: 1
}

.employees .employee span {
    color: var(--purple);
    font-weight: 700;
}

.employees .employee button {
    background-color: var(--purple);
    color: var(--white);
    font-size: 1em;
    width: 100%;
    margin-bottom: 4px;
}

.employees .employee .gp {
    display: flex;
    gap: 2px;
}
.employees .employee .gp button {
    border: 1px solid var(--purple);
    background-color: var(--white);
    color: var(--purple);
}

.employees .employee img {
    display: block;
    width: var(--img-width);
    height: var(--img-width);
    margin: 0px auto;
}


.btns {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
    gap: 5px;
    margin-top: 40px;
}
.btns button {
    margin: 0;
    width: 300px;
}

.btns button:nth-child(2) {
    background-color: var(--white);
    color: var(--purple);
}

@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
       --employees-gap: 8px;
       --employees-margin: 20px 0px 0px;
        --employee-padding: 8px;
       --img-width: 92px;
    }
    body {
        font-size: 12px;
    }

}

@media(max-width: 1000px) {

    body {
        font-size: 12px;
    }

}

@media(max-width: 700px) {
    .btns button {
        margin: 0;
        width: 100%;
        font-size: 12px;
    }
    
}