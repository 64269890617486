

.title {
    color: var(--purple);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: var(--border-radius);
    padding: var(--popup-padding);
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 9999;
    box-shadow: var(--box-shadow);
    max-height: 80%;
    overflow: auto;
}

.header {
    display: flex;
    justify-content: space-between;
}

.pop-up-input {
    border-radius: var(--border-radius);
    border: var(--border) solid var(--purple);
    width: 100%;
    height: var(--input-height);
    font-weight: bold;
    padding: 0px 5px;
    caret-color: var(--purple);
}

.btn {
    background-color: var(--purple);
    border: none;
    font-weight: 600;
    color: white;
    height: calc(var(--input-height) - 5px);
    width: 70%;
    margin: 0px auto;
    border-radius: var(--border-radius);
    transition: .3s;
    box-shadow: var(--box-shadow);
}


.btn:hover {
    opacity: 0.9;
}

.gp {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.gp div {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--purple);
    gap: 5px;
    min-width: 200px
}

.gp input {
    border-radius: var(--border-radius);
    border: var(--border) solid var(--purple);
    height: var(--input-height);
    font-weight: bold;
    padding: 0px 5px;
    caret-color: var(--purple);
    width: 100%;
}

.gp button {
    border: none;
    background-color: var(--purple);
    color: var(--white);
    font-weight: bold;
    border-radius: var(--border-radius);
    cursor: pointer;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    gap: 5px;
}

.gp button svg {
    width: 16px
}


.old_data {
    width: 100%;
    background-color: var(--light-purple);
    padding: 5px;
    border-radius: 3px;
}

.old_data span {
    color: var(--blue);
}

.divider {
    width: 100%;
    height: 2px;
    background-color: var(--purple);
}

.products {
    display: flex;
    flex-direction: column;
    gap: 5px;
    direction: ltr;
}

.products div {
    display: flex;
    gap: 4px;
}

.products_header {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;

}

.products_header span {
    background-color: var(--blue);
    padding: 5px;
    color: var(--white);
    font-weight: bold;
    text-align: center;
    border-radius: 4px;
    width: calc(85% / 8);
    font-size: 10px;
}

.products_header span:nth-child(1) {
    width: 5%!important;
    min-width: 30px;
}
.products div input:nth-child(1) {
    width: 5%!important;
    min-width: 30px;
}

.products div input {
    width: calc(85% / 8);
}

.products button {
    width: 5%;
}


.gray {
    color: white;
    background-color: var(--c-02);
}
.red {
    background-color: var(--red);
}
.purple {
    background-color: var(--purple);

}

select {
    width: 100%;
    border: 1px solid var(--purple);
    outline: none;
    border-radius: 3px;
    height: 45px;
    font-weight: bold;
}

.gp {
    display: flex;
}
@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --date-search-margin: 20px 0px 0px;
        --btn-height: 38px;
    }
    body {
        font-size: 12px;
    }

}

@media(max-width: 1000px) {
    :root {
        --date-search-margin: 20px 0px 0px;
        --btn-height: 32px;
    }
    body {
        font-size: 12px;
    }

}