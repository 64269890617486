
:root {
    --actions-btns-gaps: 24px;
    --actions-margin: 40px 0px 0px;
    --child-padding: 16px;
}

.actions {
    display: flex;
    flex-wrap: wrap;
    gap: var( --actions-btns-gaps);
    margin: var(--actions-margin);
}
.actions .btn {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.45em;
    font-weight: bold;
    height: 152px;
    border-radius: 8px;
    box-shadow: 0px 15px 36px 0px rgba(17, 35, 97, 0.10);
    transition: .3s;
}

.actions .btn:hover {
    transform: scale(1.01);
}

.b1 {
    background-color: var(--purple);
    color: white;
}
.b2 {
    color: var(--purple);
    background-color: var(--white);
    
}

.parent {
    margin-top: 20px;
    display: flex;
    gap: 5px;
}

.parent div {
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    grid-area: 3px;
    color: var(--purple);
    border-radius: var(--border-radius);
    width: 342px;
    padding: var(--child-padding);
    font-weight: 600;
    cursor: pointer;
}

.parent h3 {
    margin: 0;
}

@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --actions-btns-gaps: 12px;
        --actions-margin: 20px 0px 0px;
        --child-padding: 8px;
    }
}

@media(max-width: 1000px) {
    :root {
        --actions-btns-gaps: 8px;
        --actions-margin: 15px 0px 0px;
        --child-padding: 6px;
    }
    .btn {
        height: 100px;
    }
}

@media(max-width: 700px) {
    :root {
        --actions-btns-gaps: 8px;
        --actions-margin: 15px 0px 0px;
        --child-padding: 4px;

    }
    .btn {
        height: 80px!important;
        font-size: 12px;
    }
}