@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000');

body {
    margin: 0;
    padding: 0;
    direction: rtl;
    background-color: var(--light-purple);
    font-size: 18px;
}

button {
    user-select: none;
}

.btn {
    user-select: none;
}
* {
    font-family: Cairo;
    box-sizing: border-box;

}

.blue {
    background-color: var(--purple);
}

table tbody {
    direction: ltr;
}


:root {
   --purple: #405189;
   --blue: #1E80E0;
   --light-purple: #F3F3F9;
   --e4: #E4E4E4;
   --white: #fff;
   --green: #4DDCA6;
   --red: #F26464;
   --c-02: #999;
   --box-shadow: 0px 15px 36px 0px rgba(17, 35, 97, 0.10);
   --border-radius: 8px;
   --input-height: 56px;
   --popup-padding: 24px;
   --border: 2px;
}

button {
    user-select: none;
}

input {
    outline: none;
    border: none;
}

@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --sidebar-width: 18%;
        --sidebar-padding: 30px 0px 0px 0px;
        --links-padding: 70px 0px 0px 0px;
        --link-padding: 0px 10px 0px 0px;
        --link-gap: 8px;
        --topbar-height: 65px;
        --topbar-padding-vertical: 8px;
        --topbar-padding-horizontal: 34px;
        --btns-gap: 12px;
        --btn-width: 25px;
        --content-padding: 30px 34px;
        --boder-radius: 4px;
        --input-height: 28px;
        --popup-padding: 12px;
        --border-radius: 4px;
        --border: 1px
    }
    body {
        font-size: 12px;
        
    }

}

@media(max-width: 1000px) {
    :root {
        --sidebar-width: 18%;
        --sidebar-padding: 20px 0px 0px 0px;
        --links-padding: 20px 0px 0px 0px;
        --link-padding: 0px 10px 0px 0px;
        --link-gap: 8px;
        --topbar-height: 45px;
        --topbar-padding-vertical: 8px;
        --topbar-padding-horizontal: 34px;
        --btns-gap: 12px;
        --btn-width: 15px;
        --content-padding: 30px 34px;
        --boder-radius: 4px;
        --input-height: 28px;
        --popup-padding: 12px;
        --border-radius: 4px;
        --border: 1px
    }
    body {
        font-size: 12px;
    }

}