
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');

._invoice{
    /* background-color: white; */
}

._invoice  * {
    box-shadow: none!important;
}
._invoice h2 {
    text-align: center;
    margin: 55px 50px 0px;
    color: black;
}
._invoice .user-data {
    display: flex;
    justify-content: center;
    font-weight: 650;
} 

._invoice .invoice-data {
    width: auto!important;
}

._invoice .invoice-data td {
    padding: 3px 20px;
    width: calc(100% / 3);
}

._invoice .invoice-data div span {
    font-weight: 400;
    color: rgb(153, 153, 153);
    margin-right: 10px;
}

._invoice .products  {
    border-collapse: collapse;
    text-align: center;
    width: 96%;
    font-size: 14px;
    margin: 10px auto 20px;
}
._invoice .table thead {
    font-weight: 700;
    color: black;
}
._invoice .table tbody {
    font-weight: 400;
}
._invoice .table tr, td {
    background-color: transparent!important;
    color: black!important;
    border: 1px solid black;
    padding: 3px 20px;
    box-shadow: none!important;
}
._invoice .light {
    font-weight: 400;
}

._invoice .table  {
    border-collapse: collapse;
    width: 95%;
    text-align: center;
    margin: 10px auto;
    border-spacing: 0px;
}
._invoice .table thead {
    font-weight: 700;
    font-size: 12px;
}
._invoice .table tbody {
    font-weight: 600;
    font-size: 10px;
}
.table tr, .table  td {
    border: 1px solid;
    padding: 1px;
}
/* Pay */
._invoice .pay {
    width: 40%;
}

._invoice .pay td {
    width: calc(100% / 3);
}

._invoice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

._invoice .warranty-terms h2 {
    margin: 10px 0px;
}
._invoice .warranty-terms p {
    font-size: 12px;
    margin: 0px 20px;
}
._invoice .table.products tr td:nth-child(1)  , .table.products  tr td:nth-child(3), .table tr td:nth-child(4), .table.products tr td:nth-child(5){
    width: 50px; 
}
._invoice .products td:nth-child(2) {
    width: 45%; 
}
._invoice .buttons {
    display: flex;
    position: absolute;
    inset: 0;
}
._invoice .buttons div {
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-weight: bold;
}
._invoice .buttons .return {
    background-color: green;
}
._invoice .buttons .print-receipt {
    background-color: blue;
}
.is_showingInvoice {
    inset:  0;
    position: absolute;
    justify-content: start;
    padding-top: 35px;
    z-index: 3;
}

@media print {
    .buttons {
        display: none!important;
    }
    body {
        background-color: white;
        font-size: 14px;
    }
    .hide {
        display: none;
    }

}
