
:root {
    --sellpoints-btns-gaps: 24px;
    --sellpoints-margin: 40px 0px 0px;
}

.sellpoints {
    display: flex;
    flex-wrap: wrap;
    gap: var( --sellpoints-btns-gaps);
    margin: var(--sellpoints-margin);
}
.sellpoints .btn {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.45em;
    font-weight: bold;
    height: 152px;
    border-radius: 8px;
    box-shadow: 0px 15px 36px 0px rgba(17, 35, 97, 0.10);
    transition: .3s;
}

.sellpoints .btn:hover {
    transform: scale(1.01);
}

.sellpoints .btn.b1 {
    background-color: var(--purple);
    color: white;

}
.sellpoints .btn.b2 {
    color: var(--purple);
    background-color: var(--white);
    
}

@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --sellpoints-btns-gaps: 12px;
        --sellpoints-margin: 20px 0px 0px;
    }
}

@media(max-width: 1000px) {
    :root {
        --sellpoints-btns-gaps: 8px;
        --sellpoints-margin: 15px 0px 0px;
    }
}