.bar {
    /* background-image: url("../images/Vector.png");
    background-repeat: no-repeat;
    background-position: center; */
}
:root {
    --sidebar-padding: 53px 0px 0px 0px;
    --links-padding: 123px 0px 0px 0px;
    --link-padding: 0px 20px 0px 0px;
    --link-gap: 16px;
    --font-size: 1.25em;
    --sidebar-width: 350px;
    --topbar-height: 96px;
    --topbar-padding-vertical: 24px;
    --topbar-padding-horizontal: 80px;
    --topbar-boxshadow: 0px 15px 36px 0px rgba(17, 35, 97, 0.10);
    --btns-gap: 24px;
    --btn-width: 62px;
    --content-padding: 40px 80px;
    --bread-crump-margin: 0px 0px 40px;
}


.bar {
    display: grid;
    height: 100vh;
    grid-template-columns: var(--sidebar-width) auto;
    grid-template-rows: calc(var(--topbar-height) + var(--topbar-padding-vertical))  auto;
    transition: .7s;
    transition-delay: .09s;
    position: relative;
}

.bar.hide-sidebar {
    grid-template-columns: 0 auto;
}

.sidebar.case1 {
    padding: var(--sidebar-padding);
    overflow: auto;
    background-color: var(--purple); /* Default */
    grid-row: 1 / 3;
    transition: .6s;
}

.sidebar.case1.hide-sidebar {
    translate: 400px 0;
}

.sidebar.case1 .logo {
    display: block;
    width: 90%;
    margin: 0px auto;
}

.sidebar.case1 .links {
    width: 100%;
    padding: var(--links-padding);
}

.sidebar.case1 .links {
    display: flex;
    flex-direction: column;
}

.sidebar.case1 .links .link {
    height: 48px;
    cursor: pointer;
    padding: var(--link-padding);
    gap: var(--link-gap);
    display: flex;
    align-items: center;
    color: var(--cw, #FFF);
    font-size: 1em;
    font-weight: 700;
    transition: .3s;
}

.sidebar.case1 .links .link:hover {
    opacity: .6;
}

.sidebar.case1 .links .link.focused {
    border-right: 6px solid #4DDCA6;
    background: linear-gradient(270deg, rgba(77, 220, 166, 0.75) 0%, rgba(77, 220, 166, 0.00) 100%);
}

.topbar {
    background-color: var(--white);
    height: var(--topbar-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--topbar-padding-vertical) var(--topbar-padding-horizontal);
    box-shadow: var(--topbar-boxshadow);
    position: sticky;
    top: 0;
    z-index: 100;
}

.topbar .btns {
    display: flex;
    gap: var(--btns-gap);
    font-weight: bold;
    color: var(--purple);
}

.topbar .btns .btn {
    width: var(--btn-width);
    height: var(--btn-width);
    padding: 8px;
    border-radius: 8px;
    background-color: var(--light-purple);
    transition: .3s;
    position: relative;
    text-align: center;

}
.topbar .btns .btn:hover {
    opacity: .8;
}

.notification {
    width: 14px;
    height: 14px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    right: 2px;
    font-weight: bold;
    font-size: 0.7em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;

}

.topbar .btns  svg {
    width: 100%;
    height: 100%
}

.topbar .btns .log-out {
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color: var(--purple);
    color: white;
    font-weight: 600;
    height: var(--btn-width);
    transition: .3s;
}
.topbar .btns .log-out:hover {
    opacity: .9;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 2px;
    background-color: var(--purple);
    margin: 6px 0;
    transition: 0.4s;
}

.hide-sidebar .bar2 {
    width:  25px;
}

.content {
    padding: var(--content-padding);
    overflow: auto;
}

::-webkit-scrollbar {
    width: 10px;               /* width of the entire scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: var(--white);     
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--purple);    /* color of the scroll thumb */
    /* border-radius: 20px;      */
    height: 100px;
}

.bread-crump {
    display: flex;
    gap: 15px;
    color: var(--purple);
    font-weight: 700;
    font-size: .9em;
    margin: var(--bread-crump-margin)
}

.crump {
    cursor: pointer;
    transition: .3s;
}
.crump:hover {
    opacity: .8;
}

@media(max-width: 1802px) and (min-width: 1000px) {
    :root {
        --sidebar-width: 18%;
        --sidebar-padding: 30px 0px 0px 0px;
        --links-padding: 70px 0px 0px 0px;
        --link-padding: 0px 10px 0px 0px;
        --link-gap: 8px;
        --topbar-height: 65px;
        --topbar-padding-vertical: 8px;
        --topbar-padding-horizontal: 34px;
        --btns-gap: 12px;
        --btn-width: 42px;
        --content-padding: 30px 34px;
        --bread-crump-margin: 0px 0px 20px;
    }
    body {
        font-size: 14px;
    }
}

@media(max-width: 1000px) {
    :root {
        --sidebar-width: 18%;
        --sidebar-padding: 20px 0px 0px 0px;
        --links-padding: 40px 0px 0px 0px;
        --link-padding: 0px 10px 0px 0px;
        --link-gap: 8px;
        --topbar-height: 50px;
        --topbar-padding-vertical: 8px;
        --topbar-padding-horizontal: 15px;
        --btns-gap: 8px;
        --btn-width: 32px;
        --content-padding: 20px 15px;
        --bread-crump-margin: 0px 0px 20px;

    }
    body {
        font-size: 10px;
    }
    .topbar .btns .log-out {
        border-radius: 4px;
    }
}

@media(max-width: 1000px) {
    :root {
        --sidebar-width: 18%;
        --sidebar-padding: 15px 0px 0px 0px;
        --links-padding: 30px 0px 0px 0px;
        --link-padding: 0px 10px 0px 0px;
        --link-gap: 4px;
        --topbar-height: 50px;
        --topbar-padding-vertical: 8px;
        --topbar-padding-horizontal: 15px;
        --btns-gap: 8px;
        --btn-width: 32px;
        --content-padding: 20px 15px;
    }
    body {
        font-size: 10px;
    }
    .topbar .btns .log-out {
        border-radius: 4px;
    }
    
    .sidebar.case1 .links .link {
        height: 39px;
        cursor: pointer;
        padding: var(--link-padding);
        gap: var(--link-gap);
        display: flex;
        align-items: center;
        color: var(--cw, #FFF);
        font-size: .8em;
        font-weight: 700;
        transition: .3s;
        
    }
    .sidebar.case1 .links .link img {
        width: 20px;
    }

}


@media(max-width: 800px) {
    :root {
        --sidebar-width: 40px;
        --sidebar-padding: 15px 0px 0px 0px;
        --links-padding: 30px 0px 0px 0px;
        --link-padding: 0px 10px 0px 0px;
        --link-gap: 4px;
        --topbar-height: 50px;
        --topbar-padding-vertical: 8px;
        --topbar-padding-horizontal: 15px;
        --btns-gap: 8px;
        --btn-width: 32px;
        --content-padding: 20px 15px;
    }
    body {
        font-size: 10px;
    }
    .topbar .btns .log-out {
        border-radius: 4px;
    }
    
    .sidebar.case1 .links .link span {
        display: none;
    }
    .sidebar.case1 .logo {
        display: none;
    }

}
@media print {
    :root {
        --bread-crump-margin: 0px 0px 15px;
    }
    .topbar {
        display: none;
    }
    .sidebar.case1 {
        display: none;
    }
}