

:root {
    --date-search-margin: 40px 0px 0px;
    --btn-height: 45px;
}

.reports .date-search {
    margin: var(--date-search-margin)
}

.reports .btns {
    display: flex;   
    gap: 5px;
    margin-top: 10px;
}

.reports .btns button {
    background-color: var(--purple);
    color: white;
    font-weight: 600;
    height: var(--btn-height);
    font-size: 1em;
    /* box-shadow: var(--box-shadow); */
}

.reports .manual-report-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.reports .manual-report-inputs div {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 5px;
}

.reports .manual-report-inputs div span {
    background-color: var(--purple);
    color: white;
    font-weight: bold;
    height: 100%;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.reports .analysis {
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    margin-top: 10px;
    padding: 8px;
    font-size: 1.2em;
    font-weight: 600;
    color: var(--purple);
}

.reports .analysis div {
    display: flex;
    gap: 5px;
}

.reports .green {
    color: green;
    font-weight: bold;
}

.reports .red {
    color: var(--red);
    font-weight: bold;
}

.reports .tables-wrap {
    margin-top: 10px;
    display: flex;
    gap: 30px;
}
.reports .tables-wrap div {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 49%;
}
@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --date-search-margin: 20px 0px 0px;
        --btn-height: 38px;
    }
    body {
        font-size: 12px;
    }

}

@media(max-width: 1000px) {
    :root {
        --date-search-margin: 20px 0px 0px;
        --btn-height: 32px;
    }
    body {
        font-size: 12px;
    }

}