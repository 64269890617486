

:root {
    --title-margin: 40px 0px 16px 0px;
    --input-height: 56px;
    --table-td-height: 46px;
    --table-margin-top: 80px;
    --table-td-border: 8px;
    --table-border-spacing: 16px;
}


.customer-invoice .title {
    font-size: 1.45em;
    margin: var(--title-margin);
    color: var(--purple);
}
.customer-invoice table {
    width: 100%;
    margin-top: var(--table-margin-top);
    border-spacing: var(--table-border-spacing);
}

.customer-invoice table thead tr td {
    background-color: var(--purple)!important;
    border: none;
    color: white!important;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    border-radius: var(--table-td-border);
    box-shadow: 0px 15px 36px 0px rgba(17, 35, 97, 0.10);
}

.customer-invoice table tbody tr td {
    border: none;
    background-color: white!important;
    box-shadow: 0px 15px 36px 0px rgba(17, 35, 97, 0.10);
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 4px;
    border-radius: var(--table-td-border);
    width: calc(100% / 6);
    color: black;
}
.customer-invoice .no {
    width: fit-content;
}

.customer-invoice table tbody tr td input {
    height: 20px!important;
}

.customer-invoice table tbody tr .del {
    display: block;
    height: calc(var(--table-td-height) );
    width: calc(var(--table-td-height));
    border-radius: var(--table-td-border);
    background-color: var(--red);
    color: white;
    font-weight: bold;
    border: none;
    box-shadow: var(--box-shadow);
    cursor: pointer;
}



@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --title-margin: 20px 0px 8px 0px;
        --table-td-height: 45px;
        --table-margin-top: 40px;
        --table-td-border: 4px;
        --table-border-spacing: 10px
    }
    body {
        font-size: 14px;
    }
}


@media(max-width: 1000px) {
    :root {
        --title-margin: 15px 0px 8px 0px;
        --table-td-height: 25px;
        --table-margin-top: 25px;
        --table-td-border: 1px;
        --table-border-spacing: 4px
    }
    body {
        font-size: 14px;
    }
}