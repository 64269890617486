
:root {
    --btns-gap: 24px;
    --btn-height: 64px;
    --border-radius: 8px;
}

.storage {
    margin-top: 20px;
}
.storage .btns {
    display: flex;
    flex-wrap: wrap;
    gap: var(--btns-gap);
    margin-top: 10px;
}

.storage .btns .btn {
    min-width: 200px;
    flex: 1;
    height: var(--btn-height);
    gap: 8px;
    display: flex;
    gap: 5px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    background-color: var(--white);
    color: var(--purple);
    border: 1px solid var(--purple);
    cursor: pointer;
    transition: .4s;
}

.storage .btns .btn:hover {
    transform: skew(5deg)
}

.storage .btns:nth-child(1) .btn:nth-child(1) {
    background-color: var(--purple);
    border: none;
    color: var(--white);
}

table {
    margin-top: 10px;
    width: 100%;
    border-spacing: 4px;
}


table thead tr th {
    background-color: var(--purple)!important;
    border: none;
    color: white!important;
    font-weight: bold;
    text-align: center;
    padding: 4px 10px;
    font-size: .9em;

    border-radius: var(--table-td-border);
    box-shadow: var(--box-shadow);
}

table tbody tr td {
    border: none;
    background-color: white!important;
    box-shadow: var(--box-shadow);
    color: white;
    font-weight: bold;
    font-size: .85em;
    text-align: center;
    border-radius: var(--table-td-border);
    width: calc(100% / 7);
    color: black;
}

.index {
    width: fit-content;
}

.del {
    background-color: var(--red);
}

@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --btns-gap: 12px;
        --border-radius: 4px;
        --btn-height: 52px;
    }
    body {
        font-size: 12px;
    }

}

@media(max-width: 1000px) {
    :root {
        --btns-gap: 6px;
        --border-radius: 2px;
        --btn-height: 32px;
        --table-td-border: 2px;
    }
    body {
        font-size: 10px;
    }
    table {
        font-size: 10px;
    }
    .btn svg  {
        width: 20px;
    }

    table thead tr th {
        padding: 4px 3px!important;
    }
}
@media(max-width: 700px) {
    :root {
        --btns-gap: 6px;
        --border-radius: 2px;
        --btn-height: 24px;
        --table-td-border: 2px;
    }
    body {
        font-size: 8px;
    }
    table {
        font-size: 8px;
    }
    .btn svg  {
        width: 20px;
    }
    table {
        border-spacing: 2px!important;
        overflow: auto!important;
    }
    table td {
        padding: 2px!important;
    }
    .table-parent {
        overflow: scroll;
    }
}