
body {
    margin: 0;
    padding: 0;
    background-color: #F3F3F9;
}


.welcome {
    width: 100%;
    height: 380px;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.welcome .overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(to right,#364574,#405189);
    opacity: .9;
}


.wave {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 100px;
    transform: scaleX(8);
} 

.logo {
    margin:  0;
    color: white;
    position: relative;
    z-index: 2;
}

form {
    width: 60%;
    border-radius: 5px;
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    display: flex;
    flex-direction: column;
    top: 240px;
    padding: 30px 40px;
    direction: rtl;

}

form span.welcome-message {
    font-size: 18px; 
    text-align: center;
    color: var(--purple)!important;
    font-weight: bold;
}
form input {
    padding: 7px 5px;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--vz-input-bg-custom);
    border: 1px solid lightgray;
    outline: none;
    border-radius: 4px;
    transition: border-color .15s ease-in-out;
    margin-bottom: 10px;
}

form input:focus {
    border-color: var(--purple);
}

form label {
    font-size: 14px;
    color: var(--purple);
    box-sizing: border-box;
    display: inline-block;
    font-weight: 500;
    margin-bottom:  2px;
}

form button {
    height: 40px;
    background-color: var(--green);
    color: white;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 3px;
    border: none;
    transition: .3s;
}

form button:hover {
    opacity: .8;
}