

:root {
    --buttons-margin: 40px 0px;
    --btn-height: 64px;
    --btn-padding: 16px;
    --btn-border: 2px solid var(--purple);
    --data-gap: 24px;
}

.buttons {
    margin: var(--buttons-margin);
    display: flex;
}

.buttons button {
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-weight: bold;
    color: var(--purple);
    height: var(--btn-height);
    padding: var(--btn-padding);
    border-radius: var(--border-radius);
    border: var(--btn-border);
    box-shadow: var(--box-shadow);
}

.data {
    display: flex;
    gap: var(--data-gap)
}

.data div {
    border: var(--btn-border);
    border-radius: var(--border-radius);
    padding: var(--btn-padding);
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 700;
    color: var(--purple);
    flex: 1;
    box-shadow: var(--box-shadow);
}


table button:nth-child(1) {
    margin-left: 2px;
}
table button {
    display: inline-block;
    min-width: 60px
}
table .edit {
    background-color: var(--red);
}

.parent {
    overflow: auto;
}

@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --buttons-margin: 20px 0px;
        --btn-height: 52px;
        --btn-border: 1px solid var(--purple);
        --data-gap: 12px;
        --btn-padding: 8px;
    }
}

@media(max-width: 1000px) {
    :root {        
        --buttons-margin:10px 0px;
        --btn-height: 42px;
        --btn-border: 1px solid var(--purple);
        --data-gap: 8px;
        --btn-padding: 6px;
    }
    table {
        font-size: 10px!important;
    }
}

@media(max-width: 700px) {
    :root {
        --actions-btns-gaps: 8px;
        --actions-margin: 15px 0px 0px;
        --child-padding: 4px;

    }
    .btn {
        height: 80px!important;
        font-size: 12px;
    }
    table {
        font-size: 8px!important;
        
    }
}