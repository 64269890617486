
:root {
    --grid-gap: 18px;
    --input-height: 62px;
}


.invoice-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: var(--grid-gap);
}

.invoice-input {
    display: inline-block;
    height: var(--input-height);
    padding: 8px 16px;
    border: none;
    outline: none;
    align-items: center;
    font-size: 1em;
    font-weight: 700;
    box-shadow: 0px 15px 36px 0px rgba(17, 35, 97, 0.10);
    border-radius: 4px;
    /* max-width: 200px; Adjust the max-width as needed */
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the element's total width */
    box-sizing: border-box!important; /* Include padding and border in the element's total width */

}


.invoice-inputs  input:nth-child(1) {
    grid-column: 1 / 5;
}

.del-btn {
    grid-column: 5 / 8;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: var(--red);
    border-radius: 4px;
    height: var(--input-height);
    padding: 8px 16px;
    color: white;
    font-weight: bold;
    box-shadow: var(--box-shadow);
    cursor: pointer;

}

.invoice-inputs  input:nth-child(10) {
    grid-column: 1 / 3;
}

.complete-sell , .print {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--input-height) + 10px );
    padding: 8px 16px;
    border: none;
    outline: none;
    font-size: 1em;
    font-weight: 700;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    flex: 1;
    cursor: pointer;

}
.actions {
    display: flex;
    grid-column: 1 / 7;
    gap: var(--grid-gap);
    margin-top: 14px;
}

.complete-sell {
    background-color: var(--purple);
    color: white;
}
.print {
    background-color: white;
    color: var(--purple);
    display: flex;
    gap: 4px
}

@media(max-width: 1800px) and (min-width: 1385px) {
    :root {
        --input-height: 32px;
        --grid-gap: 10px;
    }
    body {
        font-size: 13px;
    }
}

@media(max-width: 1384px) and (min-width: 1080px) {
    :root {
        --input-height: 42px;
        --grid-gap: 8px;
    }
    body {
        font-size: 12px;
    }
}
@media(max-width: 1000px){
    :root {
        --input-height: 32px;
        --grid-gap: 4px;
    }
    body {
        font-size: 12px;
    }
    .invoice-inputs {
        display: flex;
        flex-wrap: wrap;
    }
    .invoice-inputs .invoice-input {
        width: 49%;
    }
    .del-btn {
        width: 49%;
    }
    .actions {
        margin-top: 10px;
        display: flex;
    }
}