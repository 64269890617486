

.title {
    color: var(--purple);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: var(--border-radius);
    padding: var(--popup-padding);
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 9999;
    box-shadow: var(--box-shadow);
    max-height: 80%;
    overflow: auto;
}

.header {
    display: flex;
    justify-content: space-between;
}

.pop-up-input {
    border-radius: var(--border-radius);
    border: var(--border) solid var(--purple);
    width: 100%;
    height: var(--input-height);
    font-weight: bold;
    padding: 0px 5px;
    caret-color: var(--purple);
}

.btn {
    background-color: var(--purple);
    border: none;
    font-weight: 600;
    color: white;
    height: calc(var(--input-height) - 5px);
    width: 70%;
    margin: 0px auto;
    border-radius: var(--border-radius);
    transition: .3s;
    box-shadow: var(--box-shadow);
}


.btn:hover {
    opacity: 0.9;
}

.gp {
    display: flex;
    gap: 5px;
}

.gp div {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--purple);
    gap: 5px;
}

.gp input {
    border-radius: var(--border-radius);
    border: var(--border) solid var(--purple);
    height: var(--input-height);
    font-weight: bold;
    padding: 0px 5px;
    caret-color: var(--purple);
    width: 100%;
}

.gp button {
    border: none;
    background-color: var(--purple);
    color: var(--white);
    font-weight: bold;
    border-radius: var(--border-radius);
    cursor: pointer;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    gap: 5px;
}

.gp button svg {
    width: 16px
}
