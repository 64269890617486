
:root {
    --border: 2px
}

.title {
    color: var(--purple);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: var(--border-radius);
    padding: var(--popup-padding);
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 85%;
    overflow: auto;
    z-index: 101;
    box-shadow: var(--box-shadow);
    max-height: 80%;
    overflow: auto;
    z-index: 9999;
    
}

.header {
    display: flex;
    justify-content: space-between;
}

.pop-up-input {
    border-radius: var(--border-radius);
    border: var(--border) solid var(--purple);
    width: 100%;
    height: var(--input-height);
    font-weight: bold;
    padding: 0px 5px;
    caret-color: var(--purple);
}

.btn {
    background-color: var(--purple);
    border: none;
    font-weight: 600;
    color: white;
    height: calc(var(--input-height) - 5px);
    width: 70%;
    margin: 0px auto;
    border-radius: var(--border-radius);
    transition: .3s;
    box-shadow: var(--box-shadow);
}


.btn:hover {
    opacity: 0.9;
}

.gp {
    display: flex;
    gap: 5px;
}

 input {
    border-radius: var(--border-radius);
    border: var(--border) solid var(--purple)!important;
    height: var(--input-height);
    font-weight: bold;
    padding: 0px 5px;
    caret-color: var(--purple);
}

button {
    border: none;
    background-color: var(--purple);
    color: var(--white);
    font-weight: bold;
    border-radius: var(--border-radius);
    cursor: pointer;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    gap: 5px;
}

.gp button {
    border: none;
    background-color: var(--purple);
    color: var(--white);
    font-weight: bold;
    border-radius: var(--border-radius);
    cursor: pointer;
    box-shadow: var(--box-shadow);
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    height: auto;
}


.gp button svg {
    width: 16px
}

table {
    width: 100%;
    border-spacing: 4px;
}

table tr th {
    background-color: var(--purple);
    color: white;
    border-radius: var(--border-radius);
}

table thead tr th {
    background-color: var(--purple)!important;
    border: none;
    color: white!important;
    font-weight: bold;
    text-align: center;
    padding: 4px 10px;
    border-radius: var(--table-td-border);
    box-shadow: var(--box-shadow);
}

table tbody tr td {
    border: none;
    background-color: white!important;
    box-shadow: var(--box-shadow);
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: var(--table-td-border);
    width: calc(100% / 7);
    color: black;
}

.index {
    width: fit-content;
}

@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --border: 1px;
    }
}

@media(max-width: 1000px) {
    :root {
        --border: 1px;

    }
}