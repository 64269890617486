

:root {
    --bars-border: 3px;
    --bars-height: 80px;
    --bars-padding: 8px;
    --bars-border-radius: 16px;
    --bars-margin: 40px 0px;
    --input-border-radius: 8px;
}
 

.bars {
    margin-top: 10px;
    background-color: var(--e4);
    border: var(--bars-border) solid var(--white);
    height: var(--bars-height);
    box-shadow: var(--box-shadow);
    display: flex;
    gap: 3px;
    padding: var(--bars-padding);
    border-radius: var(--bars-border-radius);
    margin: var(--bars-margin)
}
.bars button[state="enabled"] {
    background-color: var(--white);
    box-shadow: var(--box-shadow);
}

.bars button {
    flex: 1;
    background-color: var(--e4);
    box-shadow: none;
    color: var(--purple);
    cursor: pointer;
    transition: .4s;

}

.inputs {
    display: grid;
    grid-template-columns: 88% 1fr;
    gap: 5px;
}

.inputs button {
    transition: .4s;
    min-width: 100px;
    width: 100%;
    height: auto;
}

.inputs input {
    border-radius: var(--input-border-radius);
    border: var(--bars-border) solid var(--C-06, #405189);
    background: var(--C-W, #FFF);
    padding: 4px;
    color: var(--c-02);
    font-weight: 600;
    transition: .4s;
    height: var(--bars-height);
}
.inputs button:hover {
    opacity: .9;
}

h2 {
    color: var(--purple);
}


.code {
    width: 100%;
    overflow: auto;
}
 .no {
    width: fit-content;
}

table button  {
    background-color: var(--green);
    margin: 0!important;
}


.del_invoice_btn {
    width: 100%;
    background-color: var(--red);
    margin-top: 20px;
}

.data {
    display: flex;
    gap: var(--data-gap);
    margin-bottom: 10px;
}

.data div {
    border: var(--btn-border);
    border-radius: var(--border-radius);
    padding: var(--btn-padding);
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 700;
    color: var(--purple);
    flex: 1;
    box-shadow: var(--box-shadow);
}


@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --bars-border: 2px;
        --bars-height: 50px;
        --bars-padding: 4px;
        --bars-border-radius: 8px;
        --bars-margin: 20px 0px;
        --input-border-radius: 4px;
        --bars-border: 2px
    }
    body {
        font-size: 14px;
    }
}

@media(max-width: 1000px) {
    :root {
        --bars-margin: 10px 0px;
        --bars-height: 35px;
        --bars-padding: 2px;
        --bars-border-radius: 4px;
        --bars-margin: 20px 0px;
        --input-border-radius: 2px;
        --bars-border: 1px
    }
    button {
        font-size: 8px;
    }
    body {
        font-size: 10px;
    }
    .inputs {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5px;
    }
    .inputs input {
        height: 35px;
    }
    
}