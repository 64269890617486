

:root {
    --border: 2px solid var(--purple);
}

.title {
    color: var(--purple);
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: var(--border-radius);
    padding: var(--popup-padding);
    width: 90%;
    max-height: 95%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 10000;
    box-shadow: var(--box-shadow);
    max-height: 80%;
    overflow: auto;
    
}

.header {
    display: flex;
    justify-content: space-between;
}

i
.btn {
    background-color: var(--purple);
    border: none;
    font-weight: 600;
    color: white;
    height: calc(var(--input-height) - 5px);
    width: 70%;
    margin: 0px auto;
    border-radius: var(--border-radius);
    transition: .3s;
    box-shadow: var(--box-shadow);
}


.btn:hover {
    opacity: 0.9;
}

 input {
    border-radius: var(--border-radius);
    border: var(--border);
    height: var(--input-height);
    font-weight: bold;
    padding: 0px 5px;
    caret-color: var(--purple);
    width: 100%;
}


@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --border: 1px solid var(--purple);
        --data-padding: 4px;
    }
}

@media(max-width: 1000px) {
    :root {
        --border: 1px solid var(--purple);
        --data-padding: 4px;
    }
}

@media(max-width: 700px) {
    :root {
        --actions-btns-gaps: 8px;
        --actions-margin: 15px 0px 0px;
        --child-padding: 4px;

    }
    .btn {
        height: 80px!important;
        font-size: 12px;
    }
}