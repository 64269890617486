

:root {
    --date-search-margin: 40px 0px 0px;
    --btn-height: 45px;
}



.returns .btns {
    display: flex;   
    gap: 5px;
    margin-top: 10px;
}

.returns .btns button {
    background-color: var(--purple);
    color: white;
    font-weight: 600;
    height: var(--btn-height);
    font-size: 1em;
    /* box-shadow: var(--box-shadow); */
}

.del {
    background-color: var(--red);
}

.tab-btn {
    min-width: 55px;
    max-width: 80px;
    margin: 0!important;
}

.tab-btn:nth-child(2) {
    background-color: var(--purple);
}

.tab-btn.gray {
    color: white;
    background-color: var(--c-02);
    cursor: default;
}

@media(max-width: 1800px) and (min-width: 1000px) {
    :root {
        --date-search-margin: 20px 0px 0px;
        --btn-height: 38px;
    }
    body {
        font-size: 12px;
    }

}

@media(max-width: 1000px) {
    :root {
        --date-search-margin: 20px 0px 0px;
        --btn-height: 32px;
    }
    body {
        font-size: 12px;
    }

}